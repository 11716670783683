.h5-about-root {
  width: 100%;
  // height: 100vh;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-image: url(../../assets//home_bg_ani.webp);
  background-size: auto 40%;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-position-x: 85%;
  background-position-y: bottom;
  .h5-about-page-content {
    height: 100%;
    margin-top: 180px;
  }
  .h5-about-content {
    width: 100%;
    text-align: left;
  }
  .h5-aboutContentAni {
    transform: translateX(0);
    opacity: 1;
  }
  .h5-about-title {
    font-size: 24px;
    line-height: 131.5%;
    text-transform: uppercase;
    text-align: center;
  }
  .h5-about-des {
    margin-top: 18px;
    font-size: 14px;
    line-height: 28px;
  }
}
