.h5-header-root {
  width: 100%;
  height: 61px;
  z-index: 1;
  position: fixed;
  background: #f5f5f5;
  box-shadow: inset 0px -0.5px 0px #e0e0e0;
  left: 0;
  top: 0;
 

  .h5-header-root-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    .h5-header-logo {
      width: 74px;
      height: auto;
    }
  
  }

}
.ant-drawer-header {
  border-bottom: 0px solid !important;
  .ant-drawer-close {
    color: #000 !important;
  }
}

.h5-point-wrapper {
  top: 40px;
  left: 40px;
  width: 110px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .h5-point {
    margin-bottom: 18px;
    opacity: 0.5;
    cursor: pointer;
    padding-bottom: 3px;
    display: inline-block;
    padding-right: 3px;
    white-space: nowrap;
  }
  .active {
    opacity: 1;
    border-bottom: 1px solid;
    font-weight: 700;
  }
}