.ecosystem-root {
  width: 100%;
  // padding-top: 110px;
  .ecosystem-page-content {
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ecosystem-left {
    width: 50%;
    .ecosystem-left-content {
      width: 80%;
      height: 100%;
      img {
        width: 100%;
      }
      .ecosystem-left-title {
        margin-top: 40px;
        font-size: 40px;
        line-height: 131.5%;
        white-space: nowrap;
      }
      .ecosystem-left-des {
        margin-top: 15px;
        line-height: 28px;
        font-size: 14px;
      }
    }
  }
  .ecosystem-right {
    width: 50%;
    min-height: 535px;
    .ecosystem-right-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 2px;
      border-bottom: 1px solid;
      margin-bottom: 50px;
      &:hover {
        .ecosystem-right-item-title{
          svg {
            opacity: 1;
          }
        }
      }
      cursor: pointer;
      &-title {
        font-weight: 900;
        font-size: 18px;
        line-height: 131.5%;
        svg {
          margin-left: 10px;
          opacity: 0;
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
