.h5-bixin-ventures-root {
  width: 100%;
  padding-top: 61px;

  .h5-bixin-ventures-page-content {
  }

  .h5-bixin-ventures-title {
    margin-top: 30px;
    width: 100%;
    text-align: center;
    font-size: 24px;
    margin-bottom: 18px;
  }
  .h5-bixin-ventures-des {
    margin-top: 18px;
    margin-bottom: 18px;
    font-size: 13px;
    line-height: 150%;
  }

  &-content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 35px;
    img {
      width: 100%;
      cursor: pointer;
    }
  }

  .h5-bixin-ventures-btn {
    margin: auto;
    margin-top: 30px;
    width: 172.06px;
    height: 34.41px;
    background: #000000;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    svg {
      margin-left: 6px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
