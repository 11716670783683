.h5-home-root {
  width: 100%;
  // height: 100vh;
  position: relative;
  text-align: center;
  overflow: hidden;
  // background-image: url(../../assets//home_bg_ani.webp);
  // background-size: auto 65%;
  // background-repeat: no-repeat;
  // // background-attachment: fixed;
  // background-position: center bottom;
}

.h5-home-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 230px;

}
.h5-home-wrapper>.queue-anim-leaving {
    position: relative !important;
  }
.h5-home-title {
   width: 60%;
   svg {
    width: 100%;
    height: auto;
   }
}
.h5-home-des {
  width: 100%;
  padding: 0 32px;
  margin-top: 38px;
  font-weight: 700;
  font-size: 16px;
  line-height: 131.5%;
  text-align: center;
  // text-transform: capitalize;
}
