.bixin-ventures-root {
  width: 100%;
  position: relative;
  .bixin-ventures-page-content {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bixin-ventures-left {
    width: 35%;
    .bixin-ventures-title {
      font-size: 40px;
    }
    .bixin-ventures-des {
      margin-top: 20px;
      font-size: 14px;
      line-height: 200%;
    }

    .bixin-ventures-btn {
      margin-top: 30px;
      width: 172.06px;
      height: 34.41px;
      background: #000000;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      svg {
        margin-left: 6px;
      }
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #868686;
      }
    }
  }
  .bixin-ventures-right {
    width: 65%;
    padding-left: 20px;
    &-content{
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-row-gap: 35px;
      // justify-content: space-between;
      // flex-wrap: wrap;
      img {
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
