.about-root {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  overflow: hidden;

  .about-root-bg {
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/home_bg.svg);
    background-size: auto 80%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position-x: center;
    background-position-y: bottom;
  }
  .about-page-content {
    height: 100vh;
    display: flex;
    align-items: center;
  }
  .about-content {
    width: 618px;
    text-align: left;
    // transform: translateX(-100px);
    // opacity: 0;
    // transition: all 1.5s ease;
  }
  .aboutContentAni {
    transform: translateX(0);
    opacity: 1;
  }
  .about-title {
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 131.5%;
    text-transform: uppercase;
  }
  .about-des {
    font-size: 14px;
    line-height: 28px;
  }
}
