.h5-open-block-root {
  width: 100%;
  padding-top: 81px;
  .h5-open-block-page-content {

  }

    .h5-open-block-title {
      width: 100%;
      text-align: center;
      font-size: 24px;
      margin-bottom: 18px;
    }
    .h5-open-block-statistic {
      width: 100%;
      text-align: center;
      margin-top: 8px;
      font-size: 18px;
      line-height: 131.5%;
      margin-bottom: 13px;
    }
    .h5-open-block-des {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
      font-size: 13px;
      line-height: 200%;
    }
    .h5-open-block-btn {
      margin: auto;
      width: 172.06px;
      height: 34.41px;
      background: #000000;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;

      svg {
        margin-left: 6px;
      }
      display: flex;
      align-items: center;
      justify-content: center;


    }
  .h5-open-block-img {
    width: 100%;
  }
  
}
