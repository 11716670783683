.h5-bixin-wallet-root {
  width: 100%;
  padding-top: 81px;
  .h5-bixin-wallet-page-content {
  }

  .h5-bixin-wallet-title {
    width: 100%;
    text-align: center;
    font-size: 24px;
    margin-bottom: 18px;
  }

  .h5-bixin-wallet-statistic {
    margin-top: 30px;
    background: #EBEBEB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:  35px 0;

    .ant-statistic-content {
      font-size: 20px;
      font-weight: 900;
    }
  }
  .h5-bixin-wallet-des {
    margin-top: 30px;
    font-size: 13px;
    line-height: 150%;
  }
  .h5-bixin-wallet-btn {
    margin: 20px auto auto auto;
    width: 172.06px;
    height: 34.41px;
    background: #000000;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    svg {
      margin-left: 6px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .h5-bixinwallet_img {
    width: 100%;
  }
}
