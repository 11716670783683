.h5-one-key-root {
  width: 100%;
  padding-top: 81px;
  .h5-one-key-page-content {
  }

  .h5-one-key-title {
    width: 100%;
    text-align: center;
    font-size: 24px;
    margin-bottom: 18px;
  }
  .h5-one-key-statistic {
    width: 100%;
      text-align: center;
      margin-top: 8px;
      font-size: 18px;
      line-height: 131.5%;
      margin-bottom: 13px;
      
  }
  .h5-one-key-des {
    margin-top: 15px;
    font-size: 13px;
    line-height: 200%;
    text-align: center;
    margin-bottom: 15px;
  }
  .h5-one-key-btn {
    margin: auto;
    width: 172.06px;
    height: 34.41px;
    background: #000000;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    svg {
      margin-left: 6px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .onekeyswiper {
    width: 100vw;
    background-color: #ffffff;
    // margin-left: -6%;
    margin-left: -32px;
    padding: 28px 0;
  }
  // .swiper-button-prev {
  //   left: -8px;
  // }
  // .swiper-button-next {
  //   right: -8px;
  // }
  .swiper-button-next,
  .swiper-button-prev {
    &::after {
      color: #000;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .h5-onkey-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    img {
      width: 40%;
    }
  }
  .h5-onkey-slide-name {
    font-weight: 600;
    margin-bottom: 40px;
  }
  .h5-one-key-splite {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
    height: 1px;
    background-color: #000;
  }
  .h5-one-key-splite-des {
    line-height: 20px;
    font-size: 13px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
}
