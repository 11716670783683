.h5-ecosystem-root {
  width: 100%;
  padding-top: 81px;
  .h5-ecosystem-page-content {
  }

  .h5-ecosystem-img {
    margin-top: 24.5px;
    margin-bottom: 40px;
    width: 100%;
  }
  .h5-ecosystem-left-title {
    width: 100%;
    text-align: center;
    font-size: 24px;
    line-height: 131.5%;
    white-space: nowrap;
  }
  .h5-ecosystem-left-des {
    margin-top: 40px;
    line-height: 20px;
    font-size: 13px;
  }

  .h5-ecosystem-right-item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 1px solid;
    margin-bottom: 24px;
    cursor: pointer;
    &-title {
      font-weight: 900;
      font-size: 12px;
      line-height: 131.5%;
      svg {
        margin-left: 10px;
        opacity: 0;
      }
    }
  }
}
