.home-root {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  overflow: hidden;
  // background-image: url(../../assets/home_bg.svg);
  // background-size: auto 80%;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-position: center bottom;
}
.home-root-bg {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/home_bg.svg);
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: bottom;
}
.home-wrapper {
    display: inline-block;
    position: absolute;
    top: 55%;
    margin: auto;
    left: 0;
    right: 0;

}
.home-wrapper>.queue-anim-leaving {
    position: relative !important;
  }
.home-title {
    /* width: 30%; */
}
.home-des {
  margin-top: 65px;
  font-weight: 700;
  font-size: 30px;
  line-height: 131.5%;
  text-align: center;
}
