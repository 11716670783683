.whyus-root {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .why-page-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .why-title {
    margin-bottom: 29px;
    font-size: 40px;
    line-height: 131.5%;
    text-transform: uppercase;
  }
  .wyhus-childWrapper {
    width: 100%;
    .wyh-item-img-wrapper {
      width: 100%;
      height: 0;
      padding-bottom: 53.3%;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      &:hover{
        img {
          transform: scale(1.2);
          transition: all 0.3s ease-in;
          // animation: transform 0.5s ease-in-out ;
        }
      }
    }
    .wyh-item-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .wyh-item-title {
      margin-top: 29px;
      font-weight: 900;
      font-size: 24px;
      line-height: 32px;
    }
    .wyh-item-des {
      padding-top: 19px;
      height: 150px;
      font-size: 14px;
      line-height: 28px;
    }
  }
}
