.point-wrapper {
  position: fixed;
  z-index: 1;
  top: 40px;
  left: 40px;
  width: 110px;
  height: 100%;
  .point {
    margin-bottom: 18px;
    opacity: 0.5;
    cursor: pointer;
    padding-bottom: 3px;
    display: inline-block;
    padding-right: 3px;
    white-space: nowrap;
  }
  .active {
    opacity: 1;
    border-bottom: 1px solid;
    font-weight: 700;
  }
}
