.page-content {
  width: 70%;
  margin: auto;
  height: 100%;
  // .page-title{
  //   font-size: 48px;
  // }
}
.h5-page-content {
  // width: 90%;
  width: calc(100% - 64px) ;
  margin: auto;
  height: 100%;
}

.homeswiper {
  width: 100%;
  height: 100vh;
  .swiper-slide {
    width: 100%;
    height: 100%;
  }
  .homeswiper-prev {
    left: 50%;
    bottom: 20px;
    top: unset;
    &::after {
      content: " ";
      background: url("../assets/swiper_top.svg") no-repeat top left;
      width: 26px;
      height: 26px;
    }
  }
  .home-swiper-button-prev {
    position: absolute;
    width: 26px;
    height: 26px;
    z-index: 9999;
    cursor: pointer;
    left: 50%;
    bottom: 20px;
    top: unset;
    &::after {
      position: absolute;
      top: var(--swiper-navigation-top-offset, 50%);
      margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      content: " ";
      background: url("../assets/swiper_top.svg") no-repeat top left;
      width: 26px;
      height: 26px;
      transform: translateX(-13px);
    }
  }

  .home-swiper-button-disabled {
    opacity: 1 !important;
    &::after {
      content: " ";
      background: url("../assets/swiper_top_disable.svg") no-repeat top left !important;
      width: 18px;
      height: 26px;
      transform: translateX(-9px);
    }
  }



  // .home-swiper-button-disabled::after {
  //   content: " ";
  //   background: url("../assets/swiper_top_disable.svg") no-repeat top left !important;
  //   width: 18px;
  //   height: 26px;
  // }
  // .swiper-button-disabled {
  //   opacity: 1 !important;
  //   &::after {
  //     content: " ";
  //     background: url("../assets/swiper_top_disable.svg") no-repeat top left !important;
  //     width: 18px;
  //     height: 26px;
  //   }
  // }
  // .swiper-button-next {
  //   display: none;
  // }
}