.footer-root {
  position: fixed;
  box-sizing: border-box;
  bottom: 20px;
  width: 100%;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  .footer_icon {
    margin-right: 24px;
    cursor: pointer;
    &:hover {
      svg {
        rect {
          fill: #868686;
        }
      }
    }
  }
  .privacy {
    text-align: right;
    text-decoration-line: underline;
    font-size: 12px;
    line-height: 15px;
  }
  .footer-openblock {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: rgba(0, 0, 0, 0.4);
  }
}
