.bixin-wallet-root {
  width: 100%;
  position: relative;
  .bixin-wallet-page-content {
    // width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bixin-wallet-left {
    width: 50%;
    .bixin-wallet-title {
      font-size: 40px;
    }
    .bixin-wallet-statistic {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-statistic-content {
        font-size: 20px;
        font-weight: 900;
      }
    }
    .bixin-wallet-des {
      margin-top: 30px;
      font-size: 14px;
      line-height: 200%;
    }
    .bixin-wallet-btn {
      margin-top: 30px;
      width: 172.06px;
      height: 34.41px;
      background: #000000;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      svg {
        margin-left: 6px;
        // width: 15px;
        // path {
        //     stroke: #fff;
        // }
      }
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #868686;
      }
    }
  }
  .bixin-wallet-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content:center;
    img {
      width: 100%;
    }
  }
}
