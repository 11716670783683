.h5-footer-root {
  margin-top: 40px;
  padding: 40px 0;
  background: #EBEBEB;
  .h5-footer-icon-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .h5-footer_icon {
    margin-right: 24px;
    cursor: pointer;
  }
  .h5-privacy {
    text-align: right;
    text-decoration-line: underline;
    font-size: 12px;
    line-height: 15px;
  }
  .h5-footer-openblock {
    width: 100%;
    margin-top: 12.73px;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
  }
}
