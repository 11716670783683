.h5-whyus-root {
  width: 100%;
  padding-top: 81px;
  padding-bottom: 30px;
  background: #E1E1E1;
  .h5-why-page-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .h5-why-title {
    margin-bottom: 19px;
    font-size: 24px;
    line-height: 131.5%;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
  }
  .h5-wyhus-childWrapper {
    width: 100%;
    margin-bottom: 49px;

    .h5-wyh-item-img {
      width: 100%;
      height: auto;
    }
    .h5-wyh-item-title {
      margin-top: 24px;
      font-weight: 900;
      font-size: 16px;
      line-height: 32px;
    }
    .h5-wyh-item-des {
      padding-top: 9px;
      font-size: 14px;
      line-height: 28px;
    }
  }
}
