.one-key-root {
  width: 100%;
  position: relative;
  .one-key-page-content {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .one-key-left {
    width: 50%;
    .one-key-title {
      font-size: 40px;
    }
    .one-key-statistic {
      margin-top: 8px;
      font-size: 18px;
      line-height: 131.5%;
    }
    .one-key-des {
      max-width: 400px;
      margin-top: 20px;
      font-size: 14px;
      line-height: 200%;
    }
    .one-key-btn {
      margin-top: 30px;
      width: 172.06px;
      height: 34.41px;
      background: #000000;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      svg {
        margin-left: 6px;
        // width: 15px;
        // path {
        //     stroke: #fff;
        // }
      }
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #868686;
      }
    }
  }
  .one-key-right {
    width: 50%;
    .swiper-button-prev {
      left: -8px;
    }
    .swiper-button-next{
      right: -8px;
    }
    .swiper-button-next, .swiper-button-prev {
      &::after{
        color: #000;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .onkey-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      img {
        width: 60%;
      }
    }
    .onkey-slide-name {
      font-weight: 600;
      margin-bottom: 40px;
    }
    .one-key-splite {
      margin-top: 40px;
      margin-bottom: 20px;
      width: 100%;
      height: 1px;
      background-color: #000;
    }
    .one-key-splite-des {
      line-height: 28px;
    }
  }
}
