.page-content {
  width: 70%;
  margin: auto;
  height: 100%;
  // .page-title{
  //   font-size: 48px;
  // }
}
.global-bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  .global-bg-bixin {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-image: url(../assets/home_bixin_title.svg);
    background-size: 40% auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position-x: center;
    background-position-y: 200px;
    transition: all 0.5s ease-in-out;
    opacity: 0;
  }
  .bixinProcessStart {
    background-position-y: center;
    opacity: 1;
  }
  .minbixincc {
    background-size: 91px auto;
    background-position-y: 40px;
    background-image: url(../assets/home_bixin_title_logo.svg);
  }
  .global-bg-cc {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-image: url(../assets/home_bg_ani.webp);
    background-size: auto 80%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position-x: center;
    background-position-y: 100vh;
    transition: all 0.5s ease-in-out;
    opacity: 0;
  }
  .ccProcessStart {
    opacity: 1;
    background-position-y: bottom;
  }
  .mincc {
    transform: translateX(30%);
  }
  .opacityhide {
    opacity: 1;
  }
}
.homeswiper {
  width: 100%;
  height: 100vh;
  .swiper-slide {
    width: 100%;
    height: 100%;
  }
  .homeswiper-prev {
    left: 50%;
    bottom: 20px;
    top: unset;
    &::after {
      content: " ";
      background: url("../assets/swiper_top.svg") no-repeat top left;
      width: 26px;
      height: 26px;
    }
  }
  .home-swiper-button-prev {
    position: absolute;
    width: 26px;
    height: 26px;
    z-index: 9999;
    cursor: pointer;
    left: 50%;
    bottom: 20px;
    top: unset;
    &::after {
      position: absolute;
      top: var(--swiper-navigation-top-offset, 50%);
      margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      content: " ";
      background: url("../assets/swiper_top.svg") no-repeat top left;
      width: 26px;
      height: 26px;
      transform: translateX(-13px);
    }
  }

  .home-swiper-button-disabled {
    opacity: 1 !important;
    &::after {
      content: " ";
      background: url("../assets/swiper_top_disable.svg") no-repeat top left !important;
      width: 18px;
      height: 26px;
      transform: translateX(-9px);
    }
  }



  // .home-swiper-button-disabled::after {
  //   content: " ";
  //   background: url("../assets/swiper_top_disable.svg") no-repeat top left !important;
  //   width: 18px;
  //   height: 26px;
  // }
  // .swiper-button-disabled {
  //   opacity: 1 !important;
  //   &::after {
  //     content: " ";
  //     background: url("../assets/swiper_top_disable.svg") no-repeat top left !important;
  //     width: 18px;
  //     height: 26px;
  //   }
  // }
  // .swiper-button-next {
  //   display: none;
  // }
}